<template>
  <div class="tw-bg-main pt-4" style="height: 100vh">
    <div class="tw-container fw-bolder text-center mb-3">個人頁面</div>
    <div style="margin: 0 10px">
      <TabMenu :model="items"></TabMenu>
    </div>
    <!-- router view -->
    <div class="customer-router-view">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
// primevue
import TabMenu from 'primevue/tabmenu';

export default {
  components: {TabMenu},
  data() {
    return {
      items: [
        {
          label: '賣場列表',
          icon: 'pi pi-fw pi-list',
          to: '/customer/storeLists'
        },
        {
          label: '關聯帳號',
          icon: 'pi pi-fw pi-lock',
          to: '/customer/linkAccounts'
        },
        {
          label: '收件資訊',
          icon: 'pi pi-fw pi-user-edit',
          to: '/customer/receiptInfos'
        },
        {
          label: '登出',
          icon: 'pi pi-fw pi-home',
          to: '/customerLogin'
        },
      ]
    }
  },
  mounted() {
    this.listenLogout()
  },
  methods: {
    // 監聽登出按鈕
    listenLogout() {
      const btns = document.getElementsByClassName('p-menuitem-link')
      console.log(btns)
      let logoutBtn = null
      for (const [key, node] of Object.entries(btns)) {
        if (node.href.match(/customerLogin/gm)) logoutBtn = node
      }
      console.log(logoutBtn)
      const vm = this
      logoutBtn.addEventListener('click', function() {
        vm.$methods.delCookie('serverToken')
      }, false)
    },
  },
}
</script>